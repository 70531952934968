import type { ErrorEvent } from "@sentry/types";
export const sentryDenyRule = (event: ErrorEvent) => {
  // クライアントサイドのフィルタリングロジック
  if (event.exception && event.exception.values) {
    const errorMessage = event.exception.values[0].value ?? "";
    if (
      errorMessage.includes(
        "null is not an object (evaluating 'Object.prototype.hasOwnProperty.call(e,\"telephone\")')"
      ) ||
      errorMessage.includes(
        "null is not an object (evaluating 'Object.prototype.hasOwnProperty.call(o,\"telephone\")')"
      ) ||
      errorMessage.includes("Maximum call stack size exceeded")
    ) {
      return null;
    }
  }
  return event;
};
