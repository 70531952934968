import { useEffect, useLayoutEffect } from 'react';

import { isClient } from '@/consts/device';

/**
 * ### useIsomorphicEffect
 *
 * @使用例
 *
 * ```tsx
 * const isomorphicEffect = useIsomorphicEffect();
 * isomorphicEffect(() => {}, [])
 * ```
 */
export const useIsomorphicEffect = () => (isClient ? useLayoutEffect : useEffect);
