import type { AspidaClient, BasicHeaders } from 'aspida'
import type { Methods as Methods0 } from './degrees'
import type { Methods as Methods1 } from './generated_self_promotions'
import type { Methods as Methods2 } from './graduation_types'
import type { Methods as Methods3 } from './graduation_years'
import type { Methods as Methods4 } from './keywords'
import type { Methods as Methods5 } from './login'
import type { Methods as Methods6 } from './preauth_users'
import type { Methods as Methods7 } from './preauth_users/_id@string'
import type { Methods as Methods8 } from './prefectures'
import type { Methods as Methods9 } from './resume/edit_photo'
import type { Methods as Methods10 } from './schools'
import type { Methods as Methods11 } from './user'
import type { Methods as Methods12 } from './user/auth'
import type { Methods as Methods13 } from './user/resumes'
import type { Methods as Methods14 } from './user/resumes/_resumeId@string'
import type { Methods as Methods15 } from './user/resumes/_resumeId@string/files'
import type { Methods as Methods16 } from './user/resumes/_resumeId@string/print'
import type { Methods as Methods17 } from './user/resumes/_resumeId@string/print_status'
import type { Methods as Methods18 } from './user/resumes/_resumeId@string/send_pdf'
import type { Methods as Methods19 } from './user/self_pr_user_templates'
import type { Methods as Methods20 } from './user/self_pr_user_templates/_selfPrUserTemplatesId@string'
import type { Methods as Methods21 } from './user/shokumu_keirekishos'
import type { Methods as Methods22 } from './user/shokumu_keirekishos/_id@number'
import type { Methods as Methods23 } from './user/shokumu_keirekishos/_id@number/files'
import type { Methods as Methods24 } from './user/shokumu_keirekishos/_id@string/print'
import type { Methods as Methods25 } from './user/shokumu_keirekishos/_id@string/print_status'
import type { Methods as Methods26 } from './user/shokumu_keirekishos/_shokumuKeirekishoId@string/send_pdf'
import type { Methods as Methods27 } from './user/withdrawal_messages'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:3000/api/v1' : baseURL).replace(/\/$/, '')
  const PATH0 = '/degrees'
  const PATH1 = '/generated_self_promotions'
  const PATH2 = '/graduation_types'
  const PATH3 = '/graduation_years'
  const PATH4 = '/keywords'
  const PATH5 = '/login'
  const PATH6 = '/preauth_users'
  const PATH7 = '/prefectures'
  const PATH8 = '/resume/edit_photo'
  const PATH9 = '/schools'
  const PATH10 = '/user'
  const PATH11 = '/user/auth'
  const PATH12 = '/user/resumes'
  const PATH13 = '/files'
  const PATH14 = '/print'
  const PATH15 = '/print_status'
  const PATH16 = '/send_pdf'
  const PATH17 = '/user/self_pr_user_templates'
  const PATH18 = '/user/shokumu_keirekishos'
  const PATH19 = '/user/withdrawal_messages'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    degrees: {
      /**
       * 学歴一覧を取得します
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
      /**
       * 学歴一覧を取得します
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH0}`
    },
    generated_self_promotions: {
      /**
       * @returns OK
       */
      post: (option: { body: Methods1['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).json(),
      /**
       * @returns OK
       */
      $post: (option: { body: Methods1['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH1}`
    },
    graduation_types: {
      /**
       * 卒業状況一覧を取得します
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
      /**
       * 卒業状況一覧を取得します
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH2}`
    },
    graduation_years: {
      /**
       * 卒年一覧を取得します
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json(),
      /**
       * 卒年一覧を取得します
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH3}`
    },
    keywords: {
      /**
       * キーワード一覧を返却します。
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
      /**
       * キーワード一覧を返却します。
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH4}`
    },
    login: {
      /**
       * ログインします
       * @returns Created
       */
      post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods5['post']['resBody'], Methods5['post']['resHeaders'], Methods5['post']['status']>(prefix, PATH5, POST, option).json(),
      /**
       * ログインします
       * @returns Created
       */
      $post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods5['post']['resBody'], Methods5['post']['resHeaders'], Methods5['post']['status']>(prefix, PATH5, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH5}`
    },
    preauth_users: {
      _id: (val1: string) => {
        const prefix1 = `${PATH6}/${val1}`

        return {
          /**
           * @returns OK
           */
          put: (option: { body: Methods7['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods7['put']['resBody'], BasicHeaders, Methods7['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * @returns OK
           */
          $put: (option: { body: Methods7['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods7['put']['resBody'], BasicHeaders, Methods7['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * @returns Created
       */
      post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json(),
      /**
       * @returns Created
       */
      $post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH6}`
    },
    prefectures: {
      /**
       * 都道府県一覧を取得します
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).json(),
      /**
       * 都道府県一覧を取得します
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH7}`
    },
    resume: {
      edit_photo: {
        /**
         * 証明写真を加工します
         * @returns Created
         */
        post: (option: { body: Methods9['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods9['post']['resBody'], BasicHeaders, Methods9['post']['status']>(prefix, PATH8, POST, option, 'FormData').json(),
        /**
         * 証明写真を加工します
         * @returns Created
         */
        $post: (option: { body: Methods9['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods9['post']['resBody'], BasicHeaders, Methods9['post']['status']>(prefix, PATH8, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH8}`
      }
    },
    schools: {
      /**
       * 学校情報一覧を取得します
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json(),
      /**
       * 学校情報一覧を取得します
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH9}`
    },
    user: {
      auth: {
        /**
         * 認証しているかどうかを確認します
         * @returns OK
         */
        get: (option?: { headers?: Methods12['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH11, GET, option).json(),
        /**
         * 認証しているかどうかを確認します
         * @returns OK
         */
        $get: (option?: { headers?: Methods12['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH11, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH11}`
      },
      resumes: {
        _resumeId: (val2: string) => {
          const prefix2 = `${PATH12}/${val2}`

          return {
            files: {
              /**
               * 履歴書の証明写真と履歴書PDFを更新します
               * @returns Accepted
               */
              patch: (option: { body: Methods15['patch']['reqBody'], headers?: Methods15['patch']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods15['patch']['resBody'], BasicHeaders, Methods15['patch']['status']>(prefix, `${prefix2}${PATH13}`, PATCH, option, 'FormData').json(),
              /**
               * 履歴書の証明写真と履歴書PDFを更新します
               * @returns Accepted
               */
              $patch: (option: { body: Methods15['patch']['reqBody'], headers?: Methods15['patch']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods15['patch']['resBody'], BasicHeaders, Methods15['patch']['status']>(prefix, `${prefix2}${PATH13}`, PATCH, option, 'FormData').json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH13}`
            },
            print: {
              /**
               * 履歴書をコンビニ印刷します
               * @returns Created
               */
              post: (option: { body: Methods16['post']['reqBody'], headers?: Methods16['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json(),
              /**
               * 履歴書をコンビニ印刷します
               * @returns Created
               */
              $post: (option: { body: Methods16['post']['reqBody'], headers?: Methods16['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH14}`
            },
            print_status: {
              /**
               * コンビニ印刷可能な状態か確認します（「POST /user/resumes/{resumeId}/print」実行後、こちらのエンドポイントを実行してください）
               * @returns OK
               */
              get: (option?: { headers?: Methods17['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json(),
              /**
               * コンビニ印刷可能な状態か確認します（「POST /user/resumes/{resumeId}/print」実行後、こちらのエンドポイントを実行してください）
               * @returns OK
               */
              $get: (option?: { headers?: Methods17['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH15}`
            },
            send_pdf: {
              /**
               * 履歴書PDFを任意のメールアドレスに送信します
               * @returns Created
               */
              post: (option: { body: Methods18['post']['reqBody'], headers?: Methods18['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, `${prefix2}${PATH16}`, POST, option).json(),
              /**
               * 履歴書PDFを任意のメールアドレスに送信します
               * @returns Created
               */
              $post: (option: { body: Methods18['post']['reqBody'], headers?: Methods18['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, `${prefix2}${PATH16}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH16}`
            },
            /**
             * 履歴書を1つ取得します
             * @returns OK
             */
            get: (option?: { headers?: Methods14['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 履歴書を1つ取得します
             * @returns OK
             */
            $get: (option?: { headers?: Methods14['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 履歴書を更新します
             * @returns Accepted
             */
            patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'] | undefined, config?: T | undefined }) =>
              fetch<Methods14['patch']['resBody'], BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).json(),
            /**
             * 履歴書を更新します
             * @returns Accepted
             */
            $patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'] | undefined, config?: T | undefined }) =>
              fetch<Methods14['patch']['resBody'], BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 履歴書一覧を取得します
         * @returns OK
         */
        get: (option?: { headers?: Methods13['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH12, GET, option).json(),
        /**
         * 履歴書一覧を取得します
         * @returns OK
         */
        $get: (option?: { headers?: Methods13['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
        /**
         * 履歴書を作成します
         * @returns Created
         */
        post: (option: { body: Methods13['post']['reqBody'], headers?: Methods13['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<Methods13['post']['resBody'], BasicHeaders, Methods13['post']['status']>(prefix, PATH12, POST, option).json(),
        /**
         * 履歴書を作成します
         * @returns Created
         */
        $post: (option: { body: Methods13['post']['reqBody'], headers?: Methods13['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<Methods13['post']['resBody'], BasicHeaders, Methods13['post']['status']>(prefix, PATH12, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH12}`
      },
      self_pr_user_templates: {
        _selfPrUserTemplatesId: (val2: string) => {
          const prefix2 = `${PATH17}/${val2}`

          return {
            /**
             * ユーザーに紐づく自己PRを削除します
             */
            delete: (option?: { headers?: Methods20['delete']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods20['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * ユーザーに紐づく自己PRを削除します
             */
            $delete: (option?: { headers?: Methods20['delete']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods20['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * ユーザーに紐づく自己PRを取得します
         * @returns OK
         */
        get: (option?: { headers?: Methods19['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH17, GET, option).json(),
        /**
         * ユーザーに紐づく自己PRを取得します
         * @returns OK
         */
        $get: (option?: { headers?: Methods19['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH17, GET, option).json().then(r => r.body),
        /**
         * ユーザーに紐づく自己PRを作成します
         * @returns Created
         */
        post: (option: { body: Methods19['post']['reqBody'], headers?: Methods19['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, PATH17, POST, option).json(),
        /**
         * ユーザーに紐づく自己PRを作成します
         * @returns Created
         */
        $post: (option: { body: Methods19['post']['reqBody'], headers?: Methods19['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, PATH17, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH17}`
      },
      shokumu_keirekishos: {
        _id_number: (val2: number) => {
          const prefix2 = `${PATH18}/${val2}`

          return {
            files: {
              /**
               * @returns Accepted
               */
              patch: (option: { body: Methods23['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods23['patch']['resBody'], BasicHeaders, Methods23['patch']['status']>(prefix, `${prefix2}${PATH13}`, PATCH, option, 'FormData').json(),
              /**
               * @returns Accepted
               */
              $patch: (option: { body: Methods23['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods23['patch']['resBody'], BasicHeaders, Methods23['patch']['status']>(prefix, `${prefix2}${PATH13}`, PATCH, option, 'FormData').json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH13}`
            },
            /**
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * @returns Accepted
             */
            put: (option: { body: Methods22['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods22['put']['resBody'], BasicHeaders, Methods22['put']['status']>(prefix, prefix2, PUT, option).json(),
            /**
             * @returns Accepted
             */
            $put: (option: { body: Methods22['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods22['put']['resBody'], BasicHeaders, Methods22['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        _id_string: (val2: string) => {
          const prefix2 = `${PATH18}/${val2}`

          return {
            print: {
              /**
               * @returns Created
               */
              post: (option: { body: Methods24['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json(),
              /**
               * @returns Created
               */
              $post: (option: { body: Methods24['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH14}`
            },
            print_status: {
              /**
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json(),
              /**
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH15}`
            }
          }
        },
        _shokumuKeirekishoId: (val2: string) => {
          const prefix2 = `${PATH18}/${val2}`

          return {
            send_pdf: {
              /**
               * 職務経歴書PDFを任意のメールアドレスに送信します
               * @returns Created
               */
              post: (option: { body: Methods26['post']['reqBody'], headers?: Methods26['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix2}${PATH16}`, POST, option).json(),
              /**
               * 職務経歴書PDFを任意のメールアドレスに送信します
               * @returns Created
               */
              $post: (option: { body: Methods26['post']['reqBody'], headers?: Methods26['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
                fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, `${prefix2}${PATH16}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH16}`
            }
          }
        },
        /**
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH18, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH18, GET, option).json().then(r => r.body),
        /**
         * @returns Created
         */
        post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods21['post']['resBody'], BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).json(),
        /**
         * @returns Created
         */
        $post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods21['post']['resBody'], BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH18}`
      },
      withdrawal_messages: {
        post: (option: { body: Methods27['post']['reqBody'], headers?: Methods27['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods27['post']['status']>(prefix, PATH19, POST, option).send(),
        $post: (option: { body: Methods27['post']['reqBody'], headers?: Methods27['post']['reqHeaders'] | undefined, config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods27['post']['status']>(prefix, PATH19, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH19}`
      },
      /**
       * ユーザーを新規作成します
       * @returns Created
       */
      post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods11['post']['resBody'], Methods11['post']['resHeaders'], Methods11['post']['status']>(prefix, PATH10, POST, option).json(),
      /**
       * ユーザーを新規作成します
       * @returns Created
       */
      $post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods11['post']['resBody'], Methods11['post']['resHeaders'], Methods11['post']['status']>(prefix, PATH10, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH10}`
    }
  }
}

export default api
