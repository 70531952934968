export const BREAK_POINTS = {
  pc: 1025,
  tablet: 1024,
  sp: 599,
} as const;

/** MediaQuery */
export const mq = {
  /** 1025px 〜 の横幅に適応される */
  pc: `@media (min-width: ${BREAK_POINTS.pc}px)`,
  /** 〜 1024px の横幅に適応される */
  tablet: `@media (max-width: ${BREAK_POINTS.tablet}px)`,
  /** 〜 599px の横幅に適応される */
  sp: `@media (max-width: ${BREAK_POINTS.sp}px)`,
} as const;
