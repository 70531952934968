import axiosClient from '@aspida/axios';

import apiClient from 'api/$api';

/** APIリクエストを行うときに使用するオブジェクト */
export const customApi = (accessToken: string | undefined) =>
  apiClient(
    axiosClient(undefined, {
      baseURL: `${process.env.NEXT_PUBLIC_API_BASE_PATH}`,
      withCredentials: false,
      headers: { authorization: accessToken ?? '' },
    }),
  );
