import { Global } from '@emotion/react';
import '../global.css';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from '@/components/provider/auth-provider';
import { Toaster } from '@/components/ui/toaster';
import { GLOBAL_STYLE } from '@/styles/global-style';

import type { AppProps } from 'next/app';

import { sentryDenyRule } from '../../sentry.deny.rules';

const Toast = dynamic<Record<string, unknown>>(
  async () => await import('@/components/stores/toast').then((module) => module.Toast),
  { ssr: false },
);

const CommonDialog = dynamic<Record<string, unknown>>(
  async () => await import('@/components/stores/common-dialog').then((module) => module.CommonDialog),
  { ssr: false },
);

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Sentry.init({
      dsn: 'https://6349e679883c4249d22d7d2942c9d201@o1231154.ingest.us.sentry.io/4507565088702464',
      integrations: [Sentry.contextLinesIntegration()],

      beforeSend(event) {
        return sentryDenyRule(event);
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <AuthProvider>
          <Global styles={GLOBAL_STYLE} />
          <Component {...pageProps} />
          <Toaster />
          <Toast />

          <CommonDialog />
        </AuthProvider>
      </RecoilRoot>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default MyApp;
