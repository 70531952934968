/** Chromeだと10px、Safariだと9px以下のfont-sizeは指定できない */
export const FONT_SIZE = {
  SIZE_8: '8px',
  SIZE_10: '10px',
  SIZE_12: '12px',
  SIZE_14: '14px',
  SIZE_16: '16px',
  SIZE_18: '18px',
  SIZE_20: '20px',
  SIZE_24: '24px',
  SIZE_28: '28px',
  SIZE_32: '32px',
  SIZE_56: '56px',
  SIZE_128: '128px',
} as const;

export type FontSizeKeyType = keyof typeof FONT_SIZE;
