import { css } from '@emotion/react';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { mq } from '@/styles/media';

export const GLOBAL_STYLE = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
      "Hiragino Sans", Meiryo, sans-serif;

    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }

  html,
  body {
    font-size: ${FONT_SIZE.SIZE_16};

    ${mq.sp} {
      font-size: ${FONT_SIZE.SIZE_14};
    }
  }

  main {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  a {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
  }

  button {
    margin: 0;
    border: 0;
    border-radius: 0;
    color: #000000;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
  }

  /* Tables */
  table {
    border-color: inherit; /* 1 */
    border-collapse: collapse;
  }

  td,
  th {
    vertical-align: center;
    padding: 0 8px;
  }

  th {
    text-align: left;
    font-weight: normal;
  }

  /** Form Input */
  input,
  textarea {
    margin: 0;
    border: 0;
    /** MEMO（kimura）: Google Chrome や Microsoft edge だと、角が四角いinputタグになるのでリセットすることで角丸が消える */
    border-radius: 0;
    /** MEMO（kimura）: iOSで入力フォームをフォーカスすると画面が拡大されるのを防ぐために「font-size:16px」を設定 */
    font-size: 16px;
    /** MEMO（kimura）: デフォルトのスタイルをリセットするため。ぼかしを消すため */
    -webkit-appearance: none;
  }

  /**
   * <input type='search' />のバツボタンのスタイルを全端末で削除する
   */
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  /**
   * <input type='number' />の上下の矢印ボタンを非表示にするCSS
   * 上記と分けて記述しない場合EdgeでCSS Resetが効かなくなるので注意する
   */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    appearance: none;
  }

  /** ボタンのFocus時のstyle */
  :is(button, a):focus {
    outline: 1px solid ${COLOR.GREEN400};
  }

  /** ラベルのFocus時のstyle */
  :is(label):focus {
    color: ${COLOR.GREEN400};
  }

  /** inputのFocus時のstyle */
  :is(input, textarea):focus {
    outline: none;
    border: solid 1px ${COLOR.GREEN400};
  }

  @font-face {
    font-family: "DotGothic16";
    src: local("DotGothic16-Regular"), local("DotGothic16-Regular"),
      url("/fonts/DotGothic16-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "ZenKurenaido";
    src: local("ZenKurenaido-Regular"), local("ZenKurenaido-Regular"),
      url("/fonts/ZenKurenaido-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "KiwiMaru";
    src: local("KiwiMaru-Medium"), local("KiwiMaru-Medium"),
      url("/fonts/KiwiMaru-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "KiwiMaru";
    src: local("KiwiMaru-Light"), local("KiwiMaru-Light"),
      url("/fonts/KiwiMaru-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "KiwiMaru";
    src: local("KiwiMaru-Regular"), local("KiwiMaru-Regular"),
      url("/fonts/KiwiMaru-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "KleeOne";
    src: local("KleeOne-Regular"), local("KleeOne-Regular"),
      url("/fonts/KleeOne-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
`;
