import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { isClient } from '@/consts/device';

const STORE_KEY = 'authState';

const { persistAtom } = recoilPersist({
  key: 'jwtToken',
  storage: isClient ? localStorage : undefined,
});

/** ログイン状態をグローバルに管理するストア */
const authState = {
  /** 認証中かどうかのリクエスト（GET : user/auth）を走らせている場合に`true` */
  isAuthChecking: atom({
    key: `${STORE_KEY}/isAuthChecking`,
    default: false,
  }),
  /** ログイン済みの場合`true` | ログイン済みでない場合`false` */
  isLoggedIn: atom({
    key: `${STORE_KEY}/isLoggedIn`,
    default: false,
  }),
  /**
   * JWTトークン認証情報
   * - JWT = {ヘッダ}.{ペイロード}.{署名}
   * - レスポンスヘッダーの`x-authentication-token`の value の情報を localStorage に保存する
   * - JWTの有効期間は一律、「2週間~1ヶ月」に設定されている（2022年9月18日時点）
   */
  jwtToken: atom<string | undefined>({
    key: `${STORE_KEY}/jwtToken`,
    default: undefined,
    effects_UNSTABLE: [persistAtom],
  }),
};

export const useAuthState = () => {
  const isAuthChecking = useRecoilValue(authState.isAuthChecking);
  const isLoggedIn = useRecoilValue(authState.isLoggedIn);
  const jwtToken = useRecoilValue(authState.jwtToken);
  const setIsLoggedIn = useSetRecoilState(authState.isLoggedIn);
  const setJwtToken = useSetRecoilState(authState.jwtToken);
  const setIsAuthChecking = useSetRecoilState(authState.isAuthChecking);

  return {
    isAuthChecking,
    isLoggedIn,
    jwtToken,
    setIsLoggedIn,
    setJwtToken,
    setIsAuthChecking,
  };
};
