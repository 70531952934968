import { useAuthState } from '@/components/provider/auth-provider/store';
import { useIsomorphicEffect } from '@/hooks/use-isomorphic-effect';
import { customApi } from '@/utils/custom-api';

import type { ReactNode } from 'react';

type AuthProviderProps = {
  children: ReactNode;
};

/** ログイン済みかどうかの判定を行う`Provider Component` */
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { isAuthChecking, jwtToken, setIsLoggedIn, setIsAuthChecking } = useAuthState();
  const isomorphicEffect = useIsomorphicEffect();

  // MEMO（kimura）: 開発環境で`GET : user/auth`のリクエストを成功させるためには`mockJwt`を`customApi`に指定する
  // const mockJwt =
  //   jwtToken ?? 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjIsImlhdCI6MTY2MzUxODU2MX0.rdJu0f_iNWrxd505DOsF-zl15MdwIEyGE1cW6Iw7eYU';

  // マウント時に認証しているかどうかのエンドポイントを叩いて、グローバルステートにセットする副作用
  isomorphicEffect(() => {
    const controller = new AbortController();

    setIsAuthChecking(true);

    if (jwtToken === undefined) {
      setIsLoggedIn(false);
      setIsAuthChecking(false);
      return;
    }

    void (async (): Promise<void> => {
      try {
        // サーバーへのリクエスト（未ログインの場合は、401エラーコードを返す）
        const { message } = (await customApi(jwtToken).user.auth.$get()) as { message: 'ok' };
        setIsLoggedIn(message === 'ok');
      } catch {
        // 未ログインの場合は`isLoggedIn`のフラグを`false`にする
        setIsLoggedIn(false);
      } finally {
        // 成功しても失敗しても「認証中かどうか」のフラグは`初期値`に戻す
        setIsAuthChecking(false);
      }
    })();

    return () => controller.abort();
  }, [jwtToken]);

  return !isAuthChecking ? <>{children}</> : null;
};
