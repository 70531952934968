export const COLOR = {
  GRAY100: '#F0F1F1',
  GRAY200: '#E5E5E5',
  GRAY400: '#BABABA',
  GRAY500: '#585858',
  GRAY600: '#D9D9D9',
  LIGHT_GREEN: '#20C0AA',
  GREEN400: '#048573',
  DARK_GREEN: '#293836',

  RED400: '#C42E27',

  BLUE100: '#002EA0',
  BLUE200: '#3080C4',
  BLUE400: '#1F76BA',

  WHITE: '#ffffff',
  BLACK: '#000000',
  BLACK10: '#F9F9F9',
  BLACK20: '#F0F1F1',
  BLACK50: '#BABABA',
  BLACK80: '#606060',
  BLACK90: '#303030',
  BLACK100: '#333333',

  ORANGE10: '#FF8311',

  YELLOW000: '#FFF2DF',
  YELLOW100: '#EF9816',

  OVERLAY: 'rgba(0, 0, 0, 0.2)',
  PACKAGE_IMAGE_OVERLAY: 'rgba(0, 0, 0, 0.3)',
  TRANSPARENT: 'transparent',
} as const;

export type ColorKeyType = keyof typeof COLOR;
